/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import ErrorPage from '../ErrorPage';
import Loader from '~components/Loader';
import useGetTemplate from '~hooks/useGetTemplate';
import { useCampaign } from '~Flow';
import useSetLinks from '~hooks/useSetLinks';
import DangerouslySetHtmlContent from '~components/DangerouslySetHtmlContent';
import useQuery from '~hooks/useQuery';

const LandingPage = () => {
  const { landingHtml, termsModalHtml, surveyJson, error, loading } = useGetTemplate();
  const { provider, leadId, click_id } = useQuery();
  const { campaign, url, setProvider, setLeadId, setClickId } = useCampaign();
  useSetLinks(loading);

  useEffect(() => {
    setProvider(provider);
    setLeadId(leadId);
    setClickId(click_id);
  }, []);

  if (error) return <ErrorPage errorCode={error} />;

  if (loading) return <Loader />;

  return (
    <DangerouslySetHtmlContent
      html={landingHtml
        .replaceAll('{{campaign-theme}}', campaign.Name)
        .replaceAll(
          '{{campaign-survey-url}}',
          surveyJson
            ? `${url}/survey`
            : campaign['Redirect URL']
                ?.replace('{{provider}}', provider || '')
                .replace('{{leadId}}', leadId || '')
                .replace('{{clickId}}', click_id || ''),
        )
        .replaceAll('{{terms-modal}}', termsModalHtml)}
    />
  );
};

export default LandingPage;
